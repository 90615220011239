import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../sass/ui sass/questionCarousel.scss";

export default function QuestionCarousel() {
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          "https://667c01883c30891b865ae7e1.mockapi.io/bankyuristi/1/questions"
        );
        setQuestions(response.data);
      } catch (error) {
        setError("Error fetching questions");
        console.error("Error fetching questions", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const truncateOverview = (overview, wordLimit) => {
    const words = overview.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")} ...`;
    }
    return overview;
  };

  const highlightFirstWord = (text) => {
    const words = text.split(" ");
    if (words.length > 0) {
      const firstWord = words[0];
      const remainingText = words.slice(1).join(" ");
      return (
        <span>
          <span style={{ fontWeight: "bold", color: "red" }}>{firstWord}</span>{" "}
          {remainingText}
        </span>
      );
    }
    return text;
  };

    const highlightSecondWord = (text) => {
      const words = text.split(" ");
      if (words.length > 0) {
        const firstWord = words[0];
        const remainingText = words.slice(1).join(" ");
        return (
          <span>
            <span style={{ fontWeight: "bold", color: "green" }}>
              {firstWord}
            </span>{" "}
            {remainingText}
          </span>
        );
      }
      return text;
    };

  // Slider settings
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Handle card click to navigate to /questions/:id page
  const handleCardClick = (id) => {
    navigate(`/questions/${id}`);
  };

  return (
    <div className="question">
      <div className="container">
        <div className="question-content">
          <h1 className="question-title">Savol javoblar</h1>
          <div className="question-slider">
            {loading && <p>Loading questions...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && questions.length > 0 ? (
              <Slider {...settings}>
                {questions.map((question) => (
                  <div
                    key={question.id}
                    className="question-card"
                    onClick={() => handleCardClick(question.id)}
                  >
                    <img src={question.img} alt="" />
                    <div style={{ maxWidth: "100%" }}>
                      <h2>
                        {highlightFirstWord(
                          truncateOverview(question.title, 6)
                        )}
                      </h2>
                      <p>
                        {highlightSecondWord(
                          truncateOverview(question.overview, 30)
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              !loading && <p>No questions available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
