import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../ui/Navbar";
import "../sass/pages/journal.scss";
import Footer from "../ui/Footer";

export default function Journal() {
  const { id } = useParams();
  const [newsItems, setNewsItems] = useState([]);
  const [newsItem, setNewsItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch all news items
  const fetchNewsItems = async () => {
    try {
      const response = await axios.get(
        `https://667c01883c30891b865ae7e1.mockapi.io/bankyuristi/1/news`
      );
      setNewsItems(response.data);
    } catch (error) {
      setError("Error fetching news items");
      console.error("Error fetching news items", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch a single news item based on the id in the URL
  const fetchNewsItem = async () => {
    if (id) {
      try {
        const response = await axios.get(
          `https://667c01883c30891b865ae7e1.mockapi.io/bankyuristi/1/news/${id}`
        );
        setNewsItem(response.data);
      } catch (error) {
        setError("Error fetching news item");
        console.error("Error fetching news item", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchNewsItems();
  }, []);

  useEffect(() => {
    fetchNewsItem();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Navbar />
      <div className="journal-page">
        {id && newsItem ? (
          <div className="news-selected">
            <div className="container">
              <div className="news-selected-card">
                <div className="news-selected-img">
                  <img src={newsItem.img} alt={newsItem.title} />
                </div>
                <h1 className="news-selected-title">{newsItem.title}</h1>
                <span className="news-selected-rss-coverage">
                  <p className="news-selected-overview">{newsItem.overview}</p>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="news-unselected">
              <div className="container">
               <h1 className="news-unselected-title">To'liq jurnal</h1>
              {newsItems.map((item) => (
                <div key={item.id} className="news-unselected-card">
                  <div className="news-unselected-img">
                    <img src={item.img} alt={item.title} />
                  </div>
                  <h1>{item.title}</h1>
                  <div className="news-unselected-overview">
                    <p>{item.overview}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
