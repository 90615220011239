import React, { useState, useEffect } from "react";
import axios from "axios";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import "../sass/ui sass/currency.scss";

const Currency = ({ onRateChange }) => {
  const [currentRate, setCurrentRate] = useState(null);
  const [diff, setDiff] = useState(null);
  const [name, setName] = useState(null);
  const [date, setDate] = useState(null);
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);

  const fetchRate = async () => {
    try {
      const response = await axios.get(
        "https://cbu.uz/uz/arkhiv-kursov-valyut/json/"
      );
      const usdRate = response.data.find((rate) => rate.Ccy === "USD");
      if (usdRate) {
        const rate = parseFloat(usdRate.Rate);
        setCurrentRate(rate);
        setDiff(parseFloat(usdRate.Diff));
        setName(usdRate.CcyNm_UZ);
        setDate(usdRate.Date);
        setCode(usdRate.Code);
        if (onRateChange) onRateChange(rate); // Notify parent component
      }
    } catch (error) {
      setError("Error fetching exchange rates");
      console.error("Error fetching exchange rates", error);
    }
  };

  useEffect(() => {
    fetchRate();
    const interval = setInterval(fetchRate, 60000);
    return () => clearInterval(interval);
  }, []);

  const getDiffDetails = () => {
    if (diff > 0) {
      return {
        color: "#3f8600",
        icon: <ArrowUpOutlined />,
        value: diff.toFixed(2),
      };
    } else if (diff < 0) {
      return {
        color: "#cf1322",
        icon: <ArrowDownOutlined />,
        value: Math.abs(diff).toFixed(2),
      };
    } else {
      return {
        color: "#000000",
        icon: null,
        value: "0.00",
      };
    }
  };

  const diffDetails = getDiffDetails();

  return (
    <Row gutter={16}>
      <Col span={14}>
        <Card
          bordered={false}
          style={{
            width: "250px",
            height: "150px",
            display: "flex",
            justifyContent: "left",
            alignItems: "end",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Typography.Text className="currency-name">
              {name ? name : "Loading..."}
            </Typography.Text>
            <Typography.Text className="currency-date">
              {date ? date : "Loading..."}
            </Typography.Text>
            <Typography.Text className="currency-code">
              {code ? code : "Loading..."}
            </Typography.Text>
          </div>

          <Statistic
            title="Qiymat"
            value={currentRate !== null ? currentRate.toFixed(2) : "Loading..."}
            precision={2}
            valueStyle={{
              color: "#3f8600", // Neutral color for the rate
            }}
            prefix={<ArrowUpOutlined />}
            suffix="UZS"
          />
        </Card>
      </Col>
      <Col span={10}>
        <Card
          bordered={false}
          style={{
            width: "250px",
            height: "150px",
            display: "flex",
            justifyContent: "left",
            alignItems: "end",
          }}
        >
          <Statistic
            title="O'zgarish"
            value={diff !== null ? `${diffDetails.value}` : "Loading..."}
            precision={2}
            valueStyle={{
              color: diffDetails.color,
            }}
            prefix={diffDetails.icon}
            suffix="%"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Currency;
