import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../ui/Navbar";
import "../sass/pages/home.scss";
import HeroBackground from "../assets/hero.jpg";
import Currency from "../ui/Currency";
import QuestionCarousel from "../ui/QuestionCarousel";
import Footer from "../ui/Footer";
import Like from "../assets/icons/heart.svg";
import Liked from "../assets/icons/heart-fill.svg";
import { useNavigate } from "react-router-dom";

import "../sass/main.scss";


export default function Home() {
  const [currentRate, setCurrentRate] = useState(null);
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [likedItems, setLikedItems] = useState(new Set());
  const [error, setError] = useState(null);
  // const [questions, setQuestions] = useState([]); // Initialize questions state
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://667c01883c30891b865ae7e1.mockapi.io/bankyuristi/1/news"
        );
        const newsWithDates = response.data.map((item) => ({
          ...item,
          date: item.date || new Date().toISOString(),
        }));

        setNews(newsWithDates);
        setFilteredNews(newsWithDates); // Initialize filtered news
        console.log("Fetched news:", newsWithDates);
      } catch (error) {
        setError("Error fetching news");
        console.error("Error fetching news", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const storedLikedItems =
      JSON.parse(localStorage.getItem("likedItems")) || [];
    setLikedItems(new Set(storedLikedItems));
  }, []);

  const handleRateChange = (newRate) => {
    setCurrentRate(newRate);
  };

  const handleLike = async (id, currentLikes) => {
    if (likedItems.has(id)) {
      alert("Allaqachon bu xabarni yoqtirgansiz!");
      return;
    }

    try {
      const response = await axios.put(
        `https://667c01883c30891b865ae7e1.mockapi.io/bankyuristi/1/news/${id}`,
        { like: currentLikes + 1 }
      );

      setNews(
        news.map((item) =>
          item.id === id ? { ...item, like: response.data.like } : item
        )
      );

      const newLikedItems = new Set([...likedItems, id]);
      setLikedItems(newLikedItems);

      localStorage.setItem(
        "likedItems",
        JSON.stringify(Array.from(newLikedItems))
      );
    } catch (error) {
      console.error("Error updating likes", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const truncateOverview = (overview, wordLimit) => {
    const words = overview.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")} ...`;
    }
    return overview;
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query) {
      setFilteredNews(
        news.filter(
          (item) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.overview.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setFilteredNews(news); alert("Savolni kiriting!")
    }

  };


const navigate = useNavigate();

const handleCardClick = (id) => {
  navigate(`/journal/${id}`);
};



  return (
    <>
      <Navbar onSearch={handleSearch} />

      <div className="header">
        <div className="container">
          <div className="header-content">
            <div className="header-img">
              <img src={HeroBackground} alt="" />
            </div>
            <div>
              <h4 className="header-title">
                "Банк юристи" веб сайтига хуш келибсиз!
              </h4>
              <p className="header-overview">
                Биз мижозлар ва банклар ўртасидаги юридик муносабатларни
                таҳлиллаймиз, долзарб саволларни муҳокама қиламиз ва фойдали
                маслаҳатлар бериб борамиз.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="news">
        <div className="container">
          <div className="news-banner">
            <button className="news-banner-btn">Yangiliklar</button>
            <button className="news-banner-btn">Statistika</button>
            <button className="news-banner-btn">
              <Currency onRateChange={handleRateChange} />
            </button>
            <button className="news-banner-btn">
              1 USD ={" "}
              {currentRate !== null
                ? `${currentRate.toFixed(2)} UZS`
                : "Miqdor topilmadi"}
            </button>
          </div>

          <div>
            <h1 className="news-title">Muammolarga yechimlar</h1>

            <ul className="news-content">
              {filteredNews.map((item) => (
                <li
                  key={item.id}
                  className="news-card"
                  onClick={() => handleCardClick(item.id)}
                >
                  <img src={item.img} alt={item.title} />
                  <h3>{truncateOverview(item.title, 5)}</h3>
                  <p>{truncateOverview(item.overview, 18)}</p>
                  <div className="news-details">
                    <span className="news-date">{formatDate(item.date)}</span>

                    <div className="news-like-content">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLike(item.id, item.like);
                        }}
                        disabled={likedItems.has(item.id)}
                      >
                        {likedItems.has(item.id) ? (
                          <img className="news-heart" src={Liked} alt="Liked" />
                        ) : (
                          <img className="news-heart" src={Like} alt="Like" />
                        )}
                      </button>
                      <span className="news-likes"> {item.like}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <QuestionCarousel />

      <Footer />
    </>
  );
}
