import React, { useState } from "react";
import "../sass/ui sass/navbar.scss";
import Logo from "../assets/LOGO.png";
import Hamburger from "../assets/icons/hamburger.svg";
import User from "../assets/icons/user icon.svg";
import Search from "../assets/icons/search.svg";
import Share from "../assets/icons/share.svg";
import { NavLink, Link } from "react-router-dom";

import '../sass/main.scss'
  
const Navbar = ({ onSearch, handleToggle }) => {
  const [query, setQuery] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSearch = () => {
    onSearch(query);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="navbar-content">
          <div style={{maxWidth:"100%", display:"flex"}}>
            <Link className="navbar-logo" to="/">
              Bank Yuristi
            </Link>
            <button className="toggle-button" onClick={toggleMenu}>
              <img src={Hamburger} alt="Menu" />
            </button>
          </div>

          <ul className={`navbar-menu ${isMenuOpen ? "open" : ""}`}>
            <li>
              <NavLink to="/journal">Jurnal</NavLink>
            </li>
            <li>
              <a href="#">Savollar</a>
            </li>
            <li>
              <a href="#">Biz haqimizda</a>
            </li>
          </ul>
          <div className="navbar-features">
            <div className="navbar-search">
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Qidirish..."
              />
              <img onClick={handleSearch} src={Search} alt="" />
            </div>
            {/* Other icons */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
