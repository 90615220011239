import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router";
import { NavLink } from "react-router-dom";

import Home from "../src/components/Home";
import Currency from '../src/ui/Currency'
import Question from "./components/Questions";
import Journal from "./components/Journal";


export default function App() {
  return (
    <>
      <Router>
        <nav>
          <NavLink to="/"></NavLink>
          <NavLink to="/currency"></NavLink>
          <NavLink to="/question"></NavLink>
          <NavLink to="/journal"></NavLink>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/currency" element={<Currency />} />
          <Route path="/questions/:id?" element={<Question />} />
          <Route path="/journal/:id?" element={<Journal />} />
          <Route path="*" element={<p>Bunday bo'lim yo'q!</p>} />
        </Routes>
      </Router>
    </>
  );
}
