import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import '../sass/pages/questions.scss'
import Navbar from "../ui/Navbar";
import Footer from "../ui/Footer";


import Haader from '../sass/pages/home.scss'

export default function Questions() {
  const { id } = useParams();
  const [question, setQuestion] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(
          `https://667c01883c30891b865ae7e1.mockapi.io/bankyuristi/1/questions/${id}`
        );
        setQuestion(response.data);
      } catch (error) {
        setError("Error fetching question");
        console.error("Error fetching question", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestion();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!question) return <p>No question found.</p>;

    
      const highlightFirstWord = (text) => {
        const words = text.split(" ");
        if (words.length > 0) {
          const firstWord = words[0];
          const remainingText = words.slice(1).join(" ");
          return (
            <span>
              <span style={{ fontWeight: "bold", color: "red" }}>
                {firstWord}
              </span>{" "}
              {remainingText}
            </span>
          );
        }
        return text;
    };
    
    const highlightSecondWord = (text) => {
      const words = text.split(" ");
      if (words.length > 0) {
        const firstWord = words[0];
        const remainingText = words.slice(1).join(" ");
        return (
          <span>
            <span style={{ fontWeight: "bold", color: "green" }}>
              {firstWord}
            </span>{" "}
            {remainingText}
          </span>
        );
      }
      return text;
    };
    
    return (
        <>
        <Navbar />
        <div className="questions">
          <div className="container">
            <div className="questions-content">
              <div className="questions-selected">
                <img src={question.img} alt={question.title} />
                <p>{highlightFirstWord(question.title)}</p>
                <p>{highlightSecondWord(question.overview)}</p>
              </div>
            </div>
          </div>
            </div>
            <Footer/>
      </>
    );
}
