import React from "react";
import { Link } from "react-router-dom";

import telegram from "../assets/icons/telegram.svg";
// import instagram from "../assets/svg/instagram.svg";
import facebook from "../assets/icons/facebook.svg";
// import twitter from "../assets/svg/twitter.svg";
// import linkedin from "../assets/svg/linkedin.svg";
// import github from "../assets/svg/github.svg";

import '../sass/ui sass/footer.scss'

import "../sass/main.scss";


export default function Footer() {
  return (
    <div>
      <footer className="footer">
        {/* <div className="footer-container">
          <div className="footer-section company">
            <h3>Банк юристи</h3>
            <p>
              Биз мижозлар ва банклар ўртасидаги юридик муносабатларни
              таҳлиллаймиз, долзарб саволларни муҳокама қиламиз ва фойдали
              маслаҳатлар бериб борамиз.
            </p>
          </div>
       
         
          <div className="footer-section contact">
            <h3>Bog'lanish</h3>
            <p>Uzbekistan, Tashkent</p>
            <p>+998991258878</p>
          </div>
        </div> */}
        <div className="footer-social">
          <p>Biz bilan ijtimoiy tarmoqlarda birga bo'ling:</p>
          <div className="social-icons">
            <Link to="#">
              <img src={facebook} alt="" />
            </Link>
            <Link to="https://t.me/bankyuristi">
              <img src={telegram} alt="" />
            </Link>
            {/* <Link to="#">
              <img src={instagram} alt="" />
            </Link> */}
            {/* <Link to="#">
              <img src={twitter} alt="" />
            </Link> */}
            {/* <Link to="#">
              <img src={linkedin} alt="" />
            </Link> */}
            {/* <Link to="#">
              <img src={github} alt="" />
            </Link> */}
          </div>
        </div>
        {/* <div className="footer-copyright">
          <p>
            © 2024 Copyright: <a href="http://sravni.com/">sravni.com</a>
          </p>
        </div> */}
      </footer>
    </div>
  );
}
